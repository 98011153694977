<template>
  <div class="app-container">
    <el-card>
      <el-table :data="roleList" border style="width: 100%" class="mgt16">
        <el-table-column label="序号" type="index" align="center" width="60px">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column prop="name" label="角色名称" align="left">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="150px">
          <template slot-scope="scope">
            <el-button type="danger" size="small" @click="openPermitAddEdit(scope.row)">分配权限</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog @close="dialogClose" @open="dialogOpen" title="分配权限"
      :visible.sync="showli"
      width="50%" minWidth="800px">
      <Addpermissions ref="addpermissions" @getdata="getdata" />
      <div class="dialog-footer pdb16 textCenter">
        <el-button @click="cancel()"> 取消 </el-button>
        <el-button type="primary" @click="updata()"> 确认 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as userApi from "@/api/users.js";
import Addpermissions from "../add/addpermissions.vue";
export default {
  components: {
    Addpermissions,
  },
  data() {
    return {
      roleList: [],
      showli: false,
      menuList: [],
      resourcesList: [],
      name: "",
      id: null,
    };
  },

  created() {
    this.getRoleList();
    this.getMenuPageList();
  },
  methods: {
    getdata(data) {
      this.resourcesList = data;
    },
    dialogClose() {
      this.showli = false;
    },
    dialogOpen() {
    },
    cancel() {
      this.showli = false;
    },
    // sure
    updata() {
      let params = {
        name: this.name,
        id: this.id,
        resources: this.resourcesList,
      };
      userApi.updataRolePagePermision(params).then(() => {
        // Vue刷新页面的三种方式
        location.reload();

        // 强制刷新页面  事件总是 触发 ?? 实效了
        // this.$eventBus.$emit("eventBus", () => {});
        // this.showli = false;
      });
    },
    // 打开 权限分配 界面
    async openPermitAddEdit(row) {
      this.id = row.id; // 角色ID
      this.name = row.name;
      let res = await userApi.getRoleList({ id: row.id });
      let roleMenuList = [];
      for (let i = 0; i < res.data.records.length; i++) {
        roleMenuList.push(res.data.records[i].resources);
      }
      if (roleMenuList[0] == null) {
        roleMenuList = [];
      }
      this.showli = true;
      this.$nextTick(() => {
        console.log("this.menuList = " + this.menuList);
        this.$refs.addpermissions.showdata(this.menuList, roleMenuList);
      });
    },
    // 角色列表
    getRoleList() {
      userApi.getRoleList({
          page: 1,
          pageSize: 10,
          classnum: "",
        })
        .then((res) => {
          this.roleList = res.data.records;
        });
    },
    // 获取所有菜单项
    async getMenuPageList() {
      let { data } = await userApi.getAllMenuPageList();
      this.menuList = data;
    },
  },
};
</script>
