<template>
  <div>
    <el-tree ref="tree" 
      :data="treeList" 
      :props="props" 
      node-key="id"
      check-strictly
      :default-expand-all="false"
      show-checkbox
      :default-checked-keys='selectedMenuIDS'
      @check-change="handleCheckChange">
    </el-tree>
  </div>
</template>

<script>
export default {
  components: {
    //formMap
  },
  props: [""],
  data() {
    return{
      backPageIDList:[], // [ { "id": 1 }, { "id": 2 } ]
      selectedMenuIDS:[], // 之前选中的 key [ 1, 2, 21, 22 ]
      treeList:[],
      props: {
        label: 'name',
        children: 'children'
      },
    }
  },
  computed: {},
  watch: {},
  created() {
    // this.handleCheckChange()
  },
  mounted() {
  },
  methods:{
    // 选择 事件处理
    handleCheckChange(e, checked) {
      if (checked)
      {
        console.log("添加 " + e.id);
        let obj = { id: e.id } 
        
        // 去除 重复添加
        let ifFind = false;
        for (let findex = 0; findex<this.backPageIDList.length; findex++) 
        {
          if (this.backPageIDList[findex].id == obj.id)
          {
            ifFind = true;
            break;
          }
        }
        // 不存在，才添加
        if (ifFind == false) { this.backPageIDList.push(obj) }

        // 第二级子节点
        if (e.children.length > 0)
        {
          for (var j=0;j<e.children.length;j++)
          {
            // 判断节点是否选中了 ？？？
            let obj_child = { id: e.children[j].id } 

            // 去除 重复添加
            let ifFind = false;
            for (let findex = 0; findex<this.backPageIDList.length; findex++) 
            {
              if (this.backPageIDList[findex].id == obj_child.id)
              {
                ifFind = true;
                break;
              }
            }
            // 不存在，才添加
            if (ifFind == false) { this.backPageIDList.push(obj_child) }
          }
        }
      } 
      else 
      {
        // 移除（取消选中）
        this.backPageIDList.forEach((item,index)=>{
          console.log("移除 " + item.id);
          if (item.id == e.id) 
          {
            // 移除 一级 节点
            this.backPageIDList.splice(index,1)
            if (e.children.length > 0)
            {
              for (var j=0;j<e.children.length;j++)
              {
                // 移除 所有 二级 节点
                for (var k=0;k<this.backPageIDList.length;k++)
                {
                  if (this.backPageIDList[k].id == e.children[j].id)
                  {
                    this.backPageIDList.splice(k,1)
                    break;
                  }
                }
              }
            }
          }
        })
      }
      console.log(this.backPageIDList,'backPageIDList');
      // 返回数据
      this.$emit('getdata', this.backPageIDList)
    },

    // 初始化：显示界面数据
    showdata(allMenuList, roleIDSList) {
      
      // 必修要有，取消上一个role的选中值，刷新 当前role的 选中IDS
      // 如果没有，会出错
      this.$nextTick(()=> {
        this.$refs.tree.setCheckedKeys(this.selectedPageIDList);
      })

      // 全部的菜单 -> 全部的菜单的 IDS
      let menuIDS = allMenuList.map(item => {
        return item.id
      })
      
      // 所选的菜单 -> 所选的菜单 IDS
      if (roleIDSList.length > 0) {
        this.selectedMenuIDS = roleIDSList[0].map(items => {
          return items.id
        })
      }

      // 输出 调试信息
      console.log(menuIDS, this.selectedMenuIDS,'菜单项--选中项');

      let arr = [];
      if (this.selectedMenuIDS.length > 0)
      {
        for (let i = 0;i<menuIDS.length;i++)
        {
          for (let j = 0;j<this.selectedMenuIDS.length;j++)
          {
            if (menuIDS[i] == this.selectedMenuIDS[j])
            {
              console.log('字符串');
              arr.push(menuIDS[i]) // 为什么要取 选中的 大类IDS
            }
          }
        }
      } else {
        console.log(1);
      }
      
       // 重新 初始化 返回值[]
      this.backPageIDList = [];
      for (let i = 0; i<this.selectedMenuIDS.length;i++)
      {
        let obj = { id: this.selectedMenuIDS[i] } 
        // 去除 重复添加
        let ifFind = false;
        for (let findex = 0; findex<this.backPageIDList.length; findex++) 
        {
          if (this.backPageIDList[findex].id == obj.id)
          {
            ifFind = true;
            break;
          }
        }
        // 不存在，才添加
        if (ifFind == false) { this.backPageIDList.push(obj) }
      }
              
      console.log(arr, this.selectedMenuIDS, 'defa');
      let array = arr.map(item => {
        return {
          id:item
        }
      })
      this.selectedPageIDList = array
      console.log(array, this.selectedPageIDList, 'selectedPageIDList');
      // 显示全部菜单
      this.treeList = allMenuList; 
    }
  }
};
</script>
<style lang="scss" scoped>
  .el-row {height: auto;}
  .el-col { width: 100%; }
  .el-dialog {height: 354px;width: 669px;}
</style>
